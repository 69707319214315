import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import SignInForm from "../ui/SignInForm";
import { useBmapi } from "../utils/bmapi-context";
import {
  ACTIONS,
  CONSUMER_ROUTES,
  ERRORS,
  MANAGER_NAMESPACE,
  MANAGER_ROUTES,
} from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";

export default function SignIn() {
  const { baseUrl, bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const intl = useIntl();
  const { action } = useParams();

  const routes = bmapi.isConsumer() ? CONSUMER_ROUTES : MANAGER_ROUTES;

  function onSubmit(email, password) {
    startLoading();

    return bmapi
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        if (bmapi.isConsumer() && bmapi.canGoToAdmin()) {
          window.location.href = `${baseUrl}${MANAGER_NAMESPACE.slice(1)}`;
        }
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }

  useEffect(() => {
    if (action === ACTIONS.LOGGED && bmapi.settings.customLogin) {
      startLoading();
      bmapi.doSignInWithCustomToken().catch((e) => {
        if (e.code === ERRORS.COOKIES_NOT_FOUND) {
          window.location.href = bmapi.settings.customLogin.replace(
            "{cbUrl}",
            `${bmapi.baseUrl}${CONSUMER_ROUTES.SIGN_IN_ACTION.replace(
              ":action?",
              ACTIONS.LOGGED
            ).slice(1)}`
          );
        } else {
          notifyError(getErrorMessageString(e, intl));
        }
      });
    }
  }, [action, bmapi, intl, notifyError, startLoading]);

  return !action && <SignInForm onSubmit={onSubmit} routes={routes} />;
}
