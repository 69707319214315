import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import SignUpForm from "../ui/SignUpForm";
import { useBmapi } from "../utils/bmapi-context";
import { CONSUMER_ROUTES, MANAGER_ROUTES } from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";

export default function SignUp() {
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const history = useHistory();
  const intl = useIntl();

  const routes = bmapi.isConsumer() ? CONSUMER_ROUTES : MANAGER_ROUTES;

  function onSubmit(email, password) {
    startLoading();

    bmapi
      .doCreateUserWithEmailAndPassword(email, password)
      .then(() => {
        if (!bmapi.getUserInfo().user_id) {
          history.push(routes.SIGN_IN_EMAIL);
          stopLoading();
          notifySuccess(
            intl.formatMessage({
              id: "pages.signUp.confirmationEmailSent",
              defaultMessage:
                "Completa la registrazione cliccando il link ricevuto via email",
            })
          );
        }
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
        stopLoading();
      });
  }

  return <SignUpForm onSubmit={onSubmit} routes={routes} />;
}
