import React, { useEffect, useMemo } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import {
  AUTH_NAMESPACE,
  CONSUMER_NAMESPACE,
  CONSUMER_ROUTES,
  MANAGER_NAMESPACE,
  TENANTS,
  USER_STATUS,
} from "../utils/constants";
import { useBmapi } from "../utils/bmapi-context";
import PageWrapper from "./PageWrapper";
import Navbar from "./Navbar";

const isHome = (page) => !!page.home;
const withPath = (page) => !!page.routing?.path;
const byPathLength = (a, b) => b.routing.path.length - a.routing.path.length;

function Page({ routing }) {
  const Wrapper = routing.Wrapper || PageWrapper;

  return (
    <Route exact={routing.exact} key={routing.path} path={routing.path}>
      <Wrapper component={routing.Component} />
    </Route>
  );
}

export default function Routing() {
  const { baseUrl, bmapi, pages } = useBmapi();
  const location = useLocation();
  const history = useHistory();
  const cbUrl = bmapi.getTenantData().signinCallbackUrl;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const cbUrl = bmapi.getTenantData().signinCallbackUrl;
    if (bmapi.userStatus === USER_STATUS.LOGGED && cbUrl) {
      history.push(cbUrl.replace(baseUrl, "/"));
      bmapi.setCallbackUrl(false);
    }
    if (
      bmapi.userStatus === USER_STATUS.ANONYMOUS &&
      bmapi.isManager() &&
      bmapi.settings.uniqueSignIn
    ) {
      window.location.href = baseUrl + CONSUMER_ROUTES.HOME.slice(1);
    }
  }, [bmapi, bmapi.userStatus, baseUrl, history]);

  const activePages = useMemo(() => pages.filter(withPath).sort(byPathLength), [
    pages,
  ]);

  return (
    (bmapi.userStatus !== USER_STATUS.LOGGED || !cbUrl) && (
      <>
        <Switch>
          <Route path={MANAGER_NAMESPACE + AUTH_NAMESPACE} />
          <Route path={CONSUMER_NAMESPACE + AUTH_NAMESPACE} />
          <Route component={Navbar} />
        </Switch>
        <Switch>
          {activePages.map(Page)}
          {bmapi.tenant === TENANTS.LUCCA && (
            <Redirect
              from={CONSUMER_ROUTES.LUCCA_CANCEL}
              to={CONSUMER_ROUTES.LUCCA_BUY}
            />
          )}
          <Redirect to={activePages.find(isHome).routing?.path} />
        </Switch>
      </>
    )
  );
}
