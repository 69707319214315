import React from "react";
import { useIntl } from "react-intl";

import { MenuItem, TextField } from "@material-ui/core";

import { common } from "../../../messages";
import { useBmapi } from "../../../utils/bmapi-context";
import { langs } from "../../../utils/dictionaries";

export function Language({ value, onChange }) {
  const intl = useIntl();
  const { bmapi } = useBmapi();

  return (
    <TextField
      fullWidth
      label={intl.formatMessage(common.language)}
      margin="normal"
      name="language"
      onChange={onChange}
      select
      value={value}
    >
      <MenuItem value="">{intl.formatMessage(common.automatic)}</MenuItem>
      {langs
        .filter((l) => bmapi.settings.languages.includes(l.value))
        .map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  );
}
