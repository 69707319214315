import React from "react";
import { useIntl } from "react-intl";

import { InputAdornment } from "@material-ui/core";

import NumberInput from "../../NumberInput";
import { form } from "../../../messages";
import { PRODUCTS } from "../../../utils/constants";

export default function Reimbursement({ values, handleChange, campaign }) {
  const intl = useIntl();

  return (
    [PRODUCTS.SHOPPING_CARD, PRODUCTS.COUPON_DISCOUNT_VALUE].includes(
      values.front_end_type
    ) && (
      <NumberInput
        cents
        name="reimbursement_rate"
        label={intl.formatMessage(form.reimbursement)}
        value={values.reimbursement_rate}
        onChange={handleChange("reimbursement_rate")}
        fullWidth
        margin="normal"
        max={100}
        disabled={!!campaign}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
    )
  );
}
