import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";

import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import Title from "../../ui/Title";
import { useCampaigns } from "../../utils/campaigns";
import LimitProgress from "../../ui/LimitProgress";
import { BUSINESS_TYPES, USER_ROLES } from "../../utils/constants";
import { common, confirm, notifications, subscriptions } from "../../messages";
import { getPermName, rolesLabels } from "../../components/ManageStores";
import Confirm from "../../ui/Confirm";

export default function ManageSubscription() {
  const intl = useIntl();
  const { bmapi, notifyError, notifySuccess } = useBmapi();
  const [businesses, setBusinesses] = useState(false);
  const [permissions, setPermissions] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [saving, setSaving] = useState(false);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const { campaigns, loadCampaigns } = useCampaigns();

  const permToName = (code) => getPermName(code, intl);

  const handleRevokeManager = () => {
    setSaving(true);
    return bmapi
      .deleteSubscriptionPermission(deleteRequest.id)
      .then(() => {
        notifySuccess(intl.formatMessage(notifications.managerRemoved));
        setDeleteRequest(false);
        update();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const handleCreateManager = (e) => {
    e.preventDefault();
    setSaving(true);

    bmapi
      .createSubscriptionsManager(email.trim(), role)
      .then(() => {
        notifySuccess(intl.formatMessage(notifications.managerAdded));
        setOpenDialog(false);
        setEmail("");
        update();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const alphaSortManager = (a, b) => {
    return permToName(a.permission) !== permToName(b.permission)
      ? permToName(a.permission).localeCompare(permToName(b.permission))
      : a.email.localeCompare(b.email);
  };

  const update = useCallback(() => {
    return Promise.all([
      bmapi.getUserBusiness(),
      bmapi.getSubscriptionStats(),
      bmapi.getManagers(),
    ])
      .then(([bs, stats, ps]) => {
        setBusinesses(
          bs.filter(
            (b) => b.type === BUSINESS_TYPES.MERCHANT && b.status === 0
          ) || []
        );
        setPermissions(ps);
        console.log(stats);
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  }, [bmapi, intl, notifyError]);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  useEffect(() => {
    update();
  }, [update]);

  return (
    <Container maxWidth="sm">
      <Title>
        {intl.formatMessage({
          id: "pages.manageSubscription.title",
          defaultMessage: "Impostazioni sottoscrizione",
        })}
      </Title>

      <Box mb={2}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {intl.formatMessage(subscriptions.limits)}
            </Typography>
            {bmapi.subscription.plan_limits.max_business > 0 && (
              <Box mt={2}>
                <Typography>{intl.formatMessage(common.stores)}</Typography>
                <LimitProgress
                  value={businesses?.length || 0}
                  limit={bmapi.subscription.plan_limits.max_business}
                />
              </Box>
            )}
            {bmapi.subscription.plan_limits.max_campaigns > 0 && (
              <Box mt={2}>
                <Typography>{intl.formatMessage(common.campaigns)}</Typography>
                <LimitProgress
                  value={campaigns?.length || 0}
                  limit={bmapi.subscription.plan_limits.max_campaigns}
                />
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>

      <Box mb={2}>
        {!!deleteRequest && (
          <Confirm
            open={!!deleteRequest}
            onConfirm={handleRevokeManager}
            onCancel={() => setDeleteRequest(false)}
            text={intl.formatMessage(confirm.deleteManager, deleteRequest)}
            flag
          />
        )}

        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          fullWidth
          maxWidth="sm"
        >
          <form onSubmit={handleCreateManager}>
            <DialogTitle>
              {intl.formatMessage({
                id: "component.manageSubscription.addManager",
                defaultMessage: "Aggiungi manager",
              })}
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label={intl.formatMessage(common.email)}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                required
                fullWidth
              />
              <TextField
                margin="dense"
                label={intl.formatMessage(common.role)}
                value={role}
                onChange={(e) => setRole(e.target.value)}
                required
                fullWidth
                select
                disabled={
                  bmapi.subscription &&
                  !bmapi.subscription.plan_limits.different_roles
                }
                helperText={
                  bmapi.subscription &&
                  !bmapi.subscription.plan_limits.different_roles
                    ? intl.formatMessage(subscriptions.notAvailableinTrial)
                    : false
                }
              >
                {Object.entries(rolesLabels)
                  .filter(([role]) => role.startsWith("SUBSCRIPTION_"))
                  .map(([role, label]) => [role, intl.formatMessage(label)])
                  .sort((a, b) => a[1].localeCompare(b[1]))
                  .map(([role, label]) => (
                    <MenuItem value={USER_ROLES[role]} key={USER_ROLES[role]}>
                      {label}
                    </MenuItem>
                  ))}
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenDialog(false)} disabled={saving}>
                {intl.formatMessage(common.cancel)}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={saving}
              >
                {intl.formatMessage(common.create)}
              </Button>
            </DialogActions>
          </form>
          {saving && <LinearProgress />}
        </Dialog>

        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {intl.formatMessage(subscriptions.managers)}
            </Typography>
            <Box my={2}>
              {(permissions || [])
                .filter((perm) => perm.resource_id === bmapi.subscription.id)
                .sort(alphaSortManager)
                .map((m) => (
                  <Typography variant="body2" key={m.email} gutterBottom>
                    <strong>{getPermName(m.permission, intl)}</strong>:{" "}
                    {m.email}
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => setDeleteRequest(m)}
                    >
                      {intl.formatMessage(common.delete)}
                    </Button>
                  </Typography>
                ))}
            </Box>
            <Button variant="contained" onClick={() => setOpenDialog(true)}>
              {intl.formatMessage({
                id: "component.manageSubscription.addManager",
                defaultMessage: "Aggiungi manager",
              })}
            </Button>
          </CardContent>
        </Card>
      </Box>

      <Box mb={2}>
        <Card>
          <CardContent>
            {intl.formatMessage(subscriptions.changePlanInfo, {
              email: (
                <Link key="info" href="mailto:info@liberacta.com">
                  info@liberacta.com
                </Link>
              ),
            })}
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
