import React from "react";

import {
  AccountCircle as AccountCircleIcon,
  Apps,
  Assignment,
  Book,
  CenterFocusStrong as CenterFocusStrongIcon,
  CloudDownload as CloudDownloadIcon,
  CloudOff,
  ConfirmationNumber,
  EmojiEvents,
  Equalizer as EqualizerIcon,
  ExitToApp as ExitIcon,
  GroupWork,
  Home as HomeIcon,
  ListAlt,
  LiveHelp,
  Loyalty as LoyaltyIcon,
  MobileScreenShare,
  MyLocation as MyLocationIcon,
  Notifications,
  OpenInNew,
  Person,
  PersonAdd,
  Room,
  School,
  Send as SendIcon,
  Settings as SettingsIcon,
  SettingsApplications,
  Store,
} from "@material-ui/icons";

import {
  CONSUMER_ROUTES,
  CONSUMER,
  FEATURES,
  LINK_TYPE,
  MANAGER_ROUTES,
  MANAGER,
  ROLES,
  TENANTS,
  USER_STATUS,
  SKINS,
} from "../utils/constants";
import { navigation } from "../messages";

import EmptyPage from "../components/EmptyPage";
import LoginPage from "../components/LoginPage";

import LuccaBuy from "../pages/custom/lcng/LuccaBuy";
import LuccaConfirm from "../pages/custom/lcng/LuccaConfirm";
import LuccaStats from "../pages/custom/lcng/LuccaStats";

import Account from "../pages/Account";
import Action from "../pages/Action";
import Activation from "../pages/Activation";
import Calendar from "../pages/consumer/Calendar";
import Campaign from "../pages/consumer/Campaign";
import CampaignClone from "../pages/manager/CampaignClone";
import CampaignCreate from "../pages/manager/CampaignCreate";
import CampaignEdit from "../pages/manager/CampaignEdit";
import CampaignEvents from "../pages/manager/CampaignEvents";
import CampaignManager from "../pages/manager/Campaign";
import CampaignNotifications from "../pages/manager/CampaignNotifications";
import Campaigns from "../pages/manager/Campaigns";
import Checkin from "../pages/consumer/Scanner";
import Content from "../pages/Content";
import Dashboard from "../pages/manager/Dashboard";
import Entry from "../pages/Entry";
import Export from "../pages/manager/Export";
import Friend from "../pages/consumer/Friend";
import FriendCode from "../pages/consumer/FriendCode";
import HomeConsumer from "../pages/consumer/Home";
import Locations from "../pages/manager/Locations";
import Logout from "../pages/Logout";
import Loops from "../pages/manager/Loops";
import Lots from "../pages/manager/Lots";
import ManagePrizes from "../pages/manager/ManagePrizes";
import ManageSubscription from "../pages/manager/ManageSubscription";
import Message from "../pages/Message";
import Messages from "../pages/Messages";
import MyLocation from "../pages/consumer/MyLocation";
import PasswordForget from "../pages/PasswordReset";
import Play from "../pages/consumer/Play";
import Pricing from "../pages/consumer/Pricing";
import Profile from "../pages/Profile";
import QrCode from "../pages/consumer/QrCode";
import Receive from "../pages/consumer/Receive";
import Reporting from "../pages/manager/Reporting";
import Reservations from "../pages/manager/Reservations";
import Scanner from "../pages/manager/Scanner";
import SendPoints from "../pages/manager/SendPoints";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import StampScanner from "../pages/consumer/StampScanner";
import Stores from "../pages/manager/Stores";
import Subscribe from "../pages/consumer/Subscribe";
import Test from "../pages/Test";
import VoidRequest from "../pages/manager/VoidRequest";
import ProductsInventory from "../pages/manager/ProductsInventory";
import SubscribePaymentOutcome from "../pages/consumer/SubscribePaymentOutcome";

export default (bmapi, app, status, baseUrl) => {
  function canView(link) {
    return (
      link &&
      !link.disabled &&
      (!link.feature || bmapi.can(link.feature)) &&
      (!link.roles ||
        bmapi.validateRoles(link.roles, bmapi.getUserInfo().role)) &&
      (!link.tenants || link.tenants.includes(bmapi.tenant))
    );
  }

  const ROUTES = app === CONSUMER ? CONSUMER_ROUTES : MANAGER_ROUTES;

  /*********** PAGE OBJECT EXAMPLE {
    disabled: Boolean,  // Hide page
    feature: FEATURES.*,  // Enable page only when feature flag is enabled in tenant configuration
    home: Boolean,  // Use the page as home
    roles: [ROLES.*],  // Enable the page only for this roles
    tenants: [TENANTS.*],  // Enable the page only for this tenants

    // Use this object to create an item in the main menu
    menu: {
      action: async function,  // Function to execute on click, it has to be async
      badge: Boolean,  // true to show a notification badge on the icon
      icon: <ReactComponent />,  // Icon component rendered
      label: String,  // Label for the menu item
      position: LINK_TYPE.*,  // It can be ACTION to show the menu item in the bottom part of the main menu
      sublabel: String,  // Optional second line for the menu label
      url: String,  // Url to link
    },
    // If there is no `action` or `url` the menu item onclick will redirect to the path found in the next `routing` object

    // Use this object if you need to add a page to the web app
    routing: {
      Component: ReactComponent,  // https://v5.reactrouter.com/web/api/Route/component
      exact: Boolean,  // https://v5.reactrouter.com/web/api/Route/exact-bool
      path: String [MANAGER_ROUTES.* || CONSUMER_ROUTES.*],  // https://v5.reactrouter.com/web/api/Route/path-string-string
      Wrapper: LoginPage || EmptyPage,  // React component to wrap the page and shared between navigations [default PageWrapper]
      [LoginPage is the one with primary color used as background and without navbar]
    },
  } ************/

  const PAGES = {
    LOGOUT: {
      feature: FEATURES.LOGOUT,
      menu: {
        icon: <ExitIcon />,
        label: navigation.logout,
        position: LINK_TYPE.ACTION,
        sublabel: bmapi.getUserInfo().email,
      },
      routing: {
        Component: Logout,
        path: ROUTES.LOGOUT,
        Wrapper: LoginPage,
      },
    },
    ACTION: {
      routing: {
        Component: Action,
        path: CONSUMER_ROUTES.ACTION,
        Wrapper: LoginPage,
      },
    },
    CAMPAIGN: {
      routing: {
        Component: Campaign,
        path: CONSUMER_ROUTES.CAMPAIGN,
      },
    },
    RECEIVE_PRODUCT: {
      routing: {
        Component: Receive,
        path: CONSUMER_ROUTES.RECEIVE,
      },
    },
    CALENDAR: {
      routing: {
        Component: Calendar,
        path: CONSUMER_ROUTES.CALENDAR,
      },
    },
    STATIC: {
      routing: {
        Component: Content,
        path: ROUTES.STATIC,
      },
    },
    PRICING: {
      menu: {
        icon: <Assignment />,
        label: navigation.subscribe,
      },
      routing: {
        Component: Pricing,
        path: CONSUMER_ROUTES.PRICING,
      },
      tenants: [TENANTS.SELF],
    },
    FAQ: {
      menu: {
        icon: <LiveHelp />,
        label: navigation.faq,
        url: ROUTES.STATIC.replace(":content?/", "faq"),
      },
    },
    TEST: {
      routing: {
        Component: Test,
        path: CONSUMER_ROUTES.TEST,
        Wrapper: EmptyPage,
      },
    },
    EKOM_LINK: {
      menu: {
        icon: <OpenInNew />,
        label: "www.ekomdiscount.it",
        url: "http://www.ekomdiscount.it/",
      },
      tenants: [TENANTS.EKOM],
    },
    SIGN_IN: {
      routing: {
        Component: SignIn,
        path: ROUTES.SIGN_IN_ACTION,
        Wrapper: LoginPage,
      },
    },
    SIGN_IN_EMAIL: {
      routing: {
        Component: SignIn,
        path: ROUTES.SIGN_IN_EMAIL,
        Wrapper: LoginPage,
      },
    },
    SIGN_UP: {
      routing: {
        Component: SignUp,
        path: ROUTES.SIGN_UP,
        Wrapper: LoginPage,
      },
    },
    PASSWORD_FORGET: {
      routing: {
        Component: PasswordForget,
        path: ROUTES.PASSWORD_FORGET,
        Wrapper: LoginPage,
      },
    },
    ENTRY: {
      home: app === MANAGER || !bmapi.can(FEATURES.PUBLIC_HOME),
      routing: {
        Component: Entry,
        exact: true,
        path: ROUTES.SIGN_IN,
        Wrapper: LoginPage,
      },
    },
    PUBLIC_HOME: {
      feature: FEATURES.PUBLIC_HOME,
      home: true,
      routing: {
        Component: HomeConsumer,
        exact: true,
        path: CONSUMER_ROUTES.HOME,
      },
    },
    FRIEND_CODE_LANDING: {
      feature: FEATURES.FRIEND_CODE,
      routing: {
        Component: FriendCode,
        path: CONSUMER_ROUTES.FRIEND_CODE,
        Wrapper: LoginPage,
      },
    },
    ACTIVATION: {
      home: true,
      routing: {
        Component: Activation,
        exact: true,
        path: ROUTES.HOME,
      },
    },
    HOME_CONSUMER: {
      home: true,
      menu: {
        icon: <HomeIcon />,
        label: navigation.home,
      },
      routing: {
        Component: HomeConsumer,
        exact: true,
        path: CONSUMER_ROUTES.HOME,
      },
    },
    SUBSCRIBE: {
      routing: {
        Component: Subscribe,
        path: CONSUMER_ROUTES.JOIN,
      },
      tenants: [TENANTS.SELF],
    },
    SUBSCRIBE_PAYMENT_OUTCOME: {
      routing: {
        Component: SubscribePaymentOutcome,
        path: CONSUMER_ROUTES.SUBSCRIBE_PAYMENT_OUTCOME,
      },
      tenants: [TENANTS.SELF],
    },
    MY_QR: {
      feature: FEATURES.MY_CODE,
      menu: {
        icon: <Apps />,
        label: navigation.qrcode,
      },
      routing: {
        Component: QrCode,
        path: CONSUMER_ROUTES.QR_CODE,
      },
    },
    MESSAGE: {
      feature: FEATURES.MESSAGES,
      routing: {
        Component: Message,
        path: CONSUMER_ROUTES.MESSAGE,
      },
    },
    MESSAGES: {
      feature: FEATURES.MESSAGES,
      menu: {
        icon: <Notifications />,
        label: navigation.notifications,
        position: LINK_TYPE.ACTION,
      },
      routing: {
        Component: Messages,
        path: CONSUMER_ROUTES.MESSAGES,
      },
    },
    CHECKIN: {
      feature: FEATURES.CHECKIN,
      menu: {
        icon: <CenterFocusStrongIcon />,
        label: navigation.checkin,
      },
      routing: {
        Component: Checkin,
        path: CONSUMER_ROUTES.SCAN,
      },
    },
    FRIEND_CODE: {
      feature: FEATURES.FRIEND_CODE,
      menu: {
        icon: <PersonAdd />,
        label: navigation.friendCode,
      },
      routing: {
        Component: Friend,
        path: CONSUMER_ROUTES.FRIEND,
      },
    },
    PLAY: {
      feature: FEATURES.INSTANT_WIN,
      routing: {
        Component: Play,
        path: CONSUMER_ROUTES.PLAY,
        Wrapper: EmptyPage,
      },
    },
    GO_TO_ADMIN_LINK: {
      disabled: !bmapi.canGoToAdmin(),
      feature: FEATURES.CROSS_LINKS,
      menu: {
        action: () =>
          Promise.resolve(
            (window.location.href = baseUrl + MANAGER_ROUTES.HOME.slice(1))
          ),
        icon: <SettingsApplications />,
        label: navigation.appManager,
        position: LINK_TYPE.ACTION,
      },
    },
    ACCOUNT_LINK: {
      disabled:
        !bmapi.can(FEATURES.ACCOUNT) && !bmapi.can(FEATURES.PROFILE_PAGE),
      menu: {
        badge:
          bmapi.isConsumer() &&
          bmapi.can(FEATURES.PROFILE_COMPLETE) &&
          !bmapi.userData?.profile_complete,
        icon: <Person />,
        label: navigation.account,
        position: LINK_TYPE.ACTION,
        url:
          bmapi.isConsumer() && bmapi.can(FEATURES.PROFILE_PAGE)
            ? ROUTES.PROFILE
            : ROUTES.ACCOUNT,
      },
    },
    ACCOUNT: {
      feature: FEATURES.ACCOUNT,
      routing: {
        Component: Account,
        path: ROUTES.ACCOUNT,
      },
    },
    PROFILE: {
      feature: FEATURES.PROFILE_PAGE,
      routing: {
        Component: Profile,
        path: ROUTES.PROFILE,
      },
    },
    LCNG_FAQ: {
      menu: {
        icon: <AccountCircleIcon />,
        label: navigation.campfireFaq,
        url: CONSUMER_ROUTES.STATIC.replace(":content?/", "faq"),
      },
      tenants: [TENANTS.LUCCA],
    },
    LCNG_BUY: {
      routing: {
        Component: LuccaBuy,
        path: CONSUMER_ROUTES.LUCCA_BUY,
      },
      tenants: [TENANTS.LUCCA],
    },
    LCNG_CONFIRM: {
      routing: {
        Component: LuccaConfirm,
        path: CONSUMER_ROUTES.LUCCA_CONFIRM,
      },
      tenants: [TENANTS.LUCCA],
    },
    SCANNER_CONSUMER: {
      feature: FEATURES.SCAN_QR_CONSUMER,
      menu: {
        icon: <CenterFocusStrongIcon />,
        label: navigation.scanner,
      },
      routing: {
        Component: StampScanner,
        path: CONSUMER_ROUTES.SCAN,
      },
    },
    CAMPAIGNS_MANAGER: {
      home: true,
      menu: {
        icon:
          bmapi.settings.skin === SKINS.EDUCATION ? (
            <School />
          ) : (
            <LoyaltyIcon />
          ),
        label: navigation.campaigns,
      },
      routing: {
        Component: Campaigns,
        path: MANAGER_ROUTES.CAMPAIGNS,
      },
    },
    DASHBOARD: {
      feature: FEATURES.VIEW_DASHBOARD,
      menu: {
        icon: <EqualizerIcon />,
        label: navigation.dashboard,
      },
      routing: {
        Component: Dashboard,
        path: MANAGER_ROUTES.DASHBOARD,
      },
    },
    CAMPAIGN_EVENTS: {
      routing: {
        Component: CampaignEvents,
        path: MANAGER_ROUTES.CAMPAIGN_EVENTS,
      },
    },
    CAMPAIGN_MANAGER: {
      routing: {
        Component: CampaignManager,
        exact: true,
        path: MANAGER_ROUTES.CAMPAIGN,
      },
    },
    CAMPAIGN_NOTIFICATIONS: {
      disabled: !bmapi.isSubscriptionManager() && !bmapi.isTenantManager(),
      feature: FEATURES.NOTIFICATIONS,
      routing: {
        Component: CampaignNotifications,
        exact: true,
        path: MANAGER_ROUTES.CAMPAIGN_NOTIFICATIONS,
      },
    },
    CAMPAIGN_CREATE: {
      feature: FEATURES.MANAGE_CAMPAIGN,
      routing: {
        Component: CampaignCreate,
        path: MANAGER_ROUTES.CREATE_CAMPAIGN,
      },
    },
    CAMPAIGN_EDIT: {
      feature: FEATURES.MANAGE_CAMPAIGN,
      routing: {
        Component: CampaignEdit,
        path: MANAGER_ROUTES.EDIT_CAMPAIGN,
      },
    },
    CAMPAIGN_CLONE: {
      feature: FEATURES.MANAGE_CAMPAIGN,
      routing: {
        Component: CampaignClone,
        path: MANAGER_ROUTES.CLONE_CAMPAIGN,
      },
    },
    RESERVATIONS: {
      routing: {
        Component: Reservations,
        path: MANAGER_ROUTES.RESERVATIONS,
      },
    },
    SCANNER_MANAGER: {
      feature: FEATURES.SCAN_QR,
      menu: {
        icon: <CenterFocusStrongIcon />,
        label: navigation.scanner,
      },
      routing: {
        Component: Scanner,
        path: MANAGER_ROUTES.SCAN,
      },
    },
    SEND_POINTS: {
      feature: FEATURES.SEND_POINTS,
      menu: {
        icon: <SendIcon />,
        label: navigation.sendPoints,
      },
      routing: {
        Component: SendPoints,
        path: MANAGER_ROUTES.SEND_POINTS,
      },
    },
    LCNG_STATS: {
      menu: {
        label: navigation.campfireStats,
      },
      routing: {
        Component: LuccaStats,
        path: MANAGER_ROUTES.LUCCA_STATS,
      },
      tenants: [TENANTS.LUCCA],
    },
    MANAGE_BUSINESSES: {
      disabled: !bmapi.canManageLoop() || !bmapi.hasExceptions(),
      menu: {
        icon: bmapi.settings.skin === SKINS.EDUCATION ? <Book /> : <Store />,
        label: navigation.stores,
      },
      routing: {
        Component: Stores,
        path: MANAGER_ROUTES.STORES,
      },
    },
    MANAGE_LOOPS: {
      disabled: !bmapi.canManageLoop(),
      feature: FEATURES.LOOPS,
      menu: {
        icon: <GroupWork />,
        label: navigation.loops,
      },
      routing: {
        Component: Loops,
        path: MANAGER_ROUTES.LOOPS,
      },
    },
    MANAGE_LOCATIONS: {
      disabled: !bmapi.canManageLoop(),
      feature: FEATURES.LOCATIONS,
      menu: {
        icon: <Room />,
        label: navigation.locations,
      },
      routing: {
        Component: Locations,
        path: MANAGER_ROUTES.LOCATIONS,
      },
    },
    REPORTING: {
      feature: FEATURES.REPORTING,
      menu: {
        icon: <ListAlt />,
        label: navigation.reporting,
      },
      routing: {
        Component: Reporting,
        path: MANAGER_ROUTES.REPORTING,
      },
    },
    EXPORT: {
      feature: FEATURES.EXPORT,
      menu: {
        icon: <CloudDownloadIcon />,
        label: navigation.export,
      },
      routing: {
        Component: Export,
        path: MANAGER_ROUTES.EXPORT,
      },
    },
    MANAGE_SUBSCRIPTION: {
      disabled: !bmapi.canManageLoop() || !bmapi.subscription,
      menu: {
        icon: <SettingsIcon />,
        label: navigation.subscriptionSettings,
      },
      routing: {
        Component: ManageSubscription,
        path: MANAGER_ROUTES.SUBSCRIPTION,
      },
    },
    VOID_REQUEST: {
      menu: {
        icon: <CloudOff />,
        label: navigation.voidRequest,
      },
      roles: ROLES.TENANT_MANAGER,
      routing: {
        Component: VoidRequest,
        path: MANAGER_ROUTES.VOID_REQUEST,
      },
    },
    MANAGE_PRIZES: {
      feature: FEATURES.INSTANT_WIN,
      menu: {
        icon: <EmojiEvents />,
        label: navigation.prizes,
      },
      roles: ROLES.TENANT_MANAGER,
      routing: {
        Component: ManagePrizes,
        path: MANAGER_ROUTES.PRIZES,
      },
    },
    MANAGE_LOTS: {
      feature: FEATURES.LOTS,
      menu: {
        icon: <ConfirmationNumber />,
        label: navigation.lots,
      },
      roles: ROLES.TENANT_MANAGER,
      routing: {
        Component: Lots,
        path: MANAGER_ROUTES.LOTS,
      },
    },
    PRODUCTS_INVENTORY: {
      routing: {
        Component: ProductsInventory,
        exact: true,
        path: MANAGER_ROUTES.PRODUCTS_INVENTORY,
      },
    },
    GO_TO_APP_LINK: {
      disabled: !bmapi.isConsumerEnabled,
      feature: FEATURES.CROSS_LINKS,
      menu: {
        action: () =>
          Promise.resolve(
            (window.location.href = baseUrl + CONSUMER_ROUTES.HOME.slice(1))
          ),
        icon: <MobileScreenShare />,
        label: navigation.appConsumer,
        position: LINK_TYPE.ACTION,
      },
    },
    MY_LOCATION: {
      feature: FEATURES.MY_LOCATION,
      menu: {
        icon: <MyLocationIcon />,
        label: navigation.myLocation,
      },
      routing: {
        Component: MyLocation,
        path: CONSUMER_ROUTES.MY_LOCATION,
      },
    },
  };

  const TREE = {
    [CONSUMER]: {
      [USER_STATUS.ANONYMOUS]: [
        PAGES.SIGN_IN,
        PAGES.SIGN_IN_EMAIL,
        PAGES.SIGN_UP,
        PAGES.PASSWORD_FORGET,
        PAGES.ENTRY,
        PAGES.PUBLIC_HOME,
        PAGES.FRIEND_CODE_LANDING,
        PAGES.CAMPAIGN,
        PAGES.CALENDAR,
        PAGES.PRICING,
        PAGES.STATIC,
        PAGES.ACTION,
        PAGES.RECEIVE_PRODUCT,
        PAGES.EKOM_LINK,
        PAGES.FAQ,
        PAGES.TEST,
      ],
      [USER_STATUS.ACTIVATION]: [
        PAGES.ACTIVATION,
        PAGES.LOGOUT,
        PAGES.STATIC,
        PAGES.ACTION,
        PAGES.EKOM_LINK,
        PAGES.FAQ,
        PAGES.TEST,
      ],
      [USER_STATUS.LOGGED]: [
        PAGES.MY_LOCATION,
        PAGES.PROFILE,
        PAGES.ACCOUNT_LINK,
        PAGES.HOME_CONSUMER,
        PAGES.SUBSCRIBE,
        PAGES.SUBSCRIBE_PAYMENT_OUTCOME,
        PAGES.SUBSCRIBE_ERROR,
        PAGES.MY_QR,
        PAGES.MESSAGE,
        PAGES.MESSAGES,
        PAGES.CHECKIN,
        PAGES.FRIEND_CODE,
        PAGES.PLAY,
        PAGES.GO_TO_ADMIN_LINK,
        PAGES.ACCOUNT,
        PAGES.LOGOUT,
        PAGES.CAMPAIGN,
        PAGES.CALENDAR,
        PAGES.PRICING,
        PAGES.STATIC,
        PAGES.ACTION,
        PAGES.RECEIVE_PRODUCT,
        PAGES.EKOM_LINK,
        PAGES.TEST,
        PAGES.LCNG_FAQ,
        PAGES.LCNG_BUY,
        PAGES.LCNG_CONFIRM,
        PAGES.SCANNER_CONSUMER,
        PAGES.FAQ,
      ],
    },
    [MANAGER]: {
      [USER_STATUS.ANONYMOUS]: [
        PAGES.SIGN_IN,
        PAGES.SIGN_IN_EMAIL,
        PAGES.SIGN_UP,
        PAGES.PASSWORD_FORGET,
        PAGES.ENTRY,
        PAGES.STATIC,
        PAGES.FAQ,
      ],
      [USER_STATUS.ACTIVATION]: [
        PAGES.ACTIVATION,
        PAGES.STATIC,
        PAGES.LOGOUT,
        PAGES.FAQ,
      ],
      [USER_STATUS.LOGGED]: [
        PAGES.CAMPAIGNS_MANAGER,
        PAGES.DASHBOARD,
        PAGES.CAMPAIGN_EVENTS,
        PAGES.CAMPAIGN_MANAGER,
        PAGES.CAMPAIGN_NOTIFICATIONS,
        PAGES.CAMPAIGN_CREATE,
        PAGES.CAMPAIGN_EDIT,
        PAGES.CAMPAIGN_CLONE,
        PAGES.RESERVATIONS,
        PAGES.SCANNER_MANAGER,
        PAGES.SEND_POINTS,
        PAGES.LCNG_STATS,
        PAGES.MANAGE_BUSINESSES,
        PAGES.MANAGE_LOOPS,
        PAGES.MANAGE_LOCATIONS,
        PAGES.REPORTING,
        PAGES.EXPORT,
        PAGES.MANAGE_SUBSCRIPTION,
        PAGES.VOID_REQUEST,
        PAGES.MANAGE_PRIZES,
        PAGES.MANAGE_LOTS,
        PAGES.PRODUCTS_INVENTORY,
        PAGES.GO_TO_APP_LINK,
        PAGES.ACCOUNT_LINK,
        PAGES.ACCOUNT,
        PAGES.PROFILE,
        PAGES.STATIC,
        PAGES.LOGOUT,
        PAGES.FAQ,
      ],
    },
  };

  return TREE[app][status].filter(canView);
};
