import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { differenceInYears, format } from "date-fns";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@material-ui/core";

import { extraProfileFields, getFormatter } from "./Account";
import { activation } from "../messages";
import {
  DirectMarketing,
  IndirectMarketing,
  InvitedBy,
  Privacy,
  Rules,
} from "../ui/forms/profile";
import Title from "../ui/Title";
import { useBmapi } from "../utils/bmapi-context";
import { FEATURES } from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";
import styles from "../utils/styles";

export default function Activation() {
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const intl = useIntl();
  const classes = styles.useStyles();
  const [values, setValues] = useState({
    direct_marketing:
      bmapi.settings.profileRequired.includes("direct_marketing") && null,
    indirect_marketing:
      bmapi.settings.profileRequired.includes("indirect_marketing") && null,
    invited_by:
      bmapi.userData?.invited_by || bmapi.getTenantData().friendCode || "",
    mobile: bmapi.userData?.mobile || "",
    privacy: !bmapi.settings.profileRequired.includes("privacy") || null,
    rules: !bmapi.settings.profileRequired.includes("rules") || null,
    gender: bmapi.userData?.gender || "",
    name: bmapi.userData?.complete_name || "",
    last_name: bmapi.userData?.last_name || "",
    birthday: bmapi.userData?.birthday
      ? new Date(bmapi.userData?.birthday)
      : null,
  });

  const handleChange = (key) => {
    const updateValue = (val) => setValues((vs) => ({ ...vs, [key]: val }));

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (typeof f === "string") updateValue(JSON.parse(f));
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  };

  const accept = useCallback(
    (e) => {
      e.preventDefault();

      if (bmapi.settings.profileRequired.includes("birthday")) {
        if (!values.birthday) {
          return notifyError(intl.formatMessage(activation.birthdayError));
        }
        if (
          bmapi.settings.minAge >=
          differenceInYears(new Date(), values.birthday)
        ) {
          return notifyError(
            intl.formatMessage(activation.birthdayMinAge, {
              minAge: bmapi.settings.minAge,
            })
          );
        }
      }
      if (!values.rules) {
        return notifyError(intl.formatMessage(activation.acceptTosError));
      }
      if (!values.privacy) {
        return notifyError(intl.formatMessage(activation.privacyError));
      }

      startLoading();
      if (typeof bmapi.getUserData().firstLogin !== "boolean") {
        bmapi.setUserData({ firstLogin: true });
      }

      bmapi
        .saveUser({
          ...values,
          birthday: values.birthday
            ? format(values.birthday, "yyyy-MM-dd")
            : "",
          metadata: {
            invited_by: values.invited_by,
            profile_version: bmapi.settings.profileVersion,
            registration_code: bmapi.getTenantData().rc || "",
          },
        })
        .then(() => bmapi.setTenantData({ rc: false }))
        .catch((e) => notifyError(getErrorMessageString(e, intl)))
        .finally(stopLoading);
    },
    [bmapi, intl, notifyError, startLoading, stopLoading, values]
  );

  useEffect(() => {
    stopLoading();
  }, [stopLoading]);

  const formatValues = getFormatter(bmapi);

  const activeFields = extraProfileFields.filter((i) =>
    bmapi.settings.profileRequired.includes(i.key)
  );

  return (
    <form onSubmit={accept}>
      <Container maxWidth="sm">
        <Title>{intl.formatMessage(activation.title)}</Title>
        <Card>
          <CardMedia image={bmapi.logo()} className={classes.tosLogo} />
          <CardContent>
            {!!activeFields.length && (
              <React.Fragment>
                <Typography variant="h6" gutterBottom>
                  {intl.formatMessage(activation.completeProfile)}
                </Typography>
                {activeFields.map((field) => (
                  <field.Component
                    key={field.key}
                    value={values[field.key]}
                    onChange={handleChange(field.key)}
                  />
                ))}
              </React.Fragment>
            )}
            {bmapi.can(FEATURES.FRIEND_CODE) && (
              <InvitedBy
                value={values.invited_by}
                onChange={handleChange("invited_by")}
                formatValues={formatValues}
                disabled={Boolean(bmapi.userData?.invited_by)}
              />
            )}
            {bmapi.settings.profileRequired.includes("rules") && (
              <Rules
                value={values.rules}
                onChange={handleChange("rules")}
                formatValues={formatValues}
              />
            )}
            {bmapi.settings.profileRequired.includes("privacy") && (
              <Privacy
                value={values.privacy}
                onChange={handleChange("privacy")}
                formatValues={formatValues}
              />
            )}
            {bmapi.settings.profileRequired.includes("direct_marketing") && (
              <DirectMarketing
                value={values.direct_marketing}
                onChange={handleChange("direct_marketing")}
                formatValues={formatValues}
              />
            )}
            {bmapi.settings.profileRequired.includes("indirect_marketing") && (
              <IndirectMarketing
                value={values.indirect_marketing}
                onChange={handleChange("indirect_marketing")}
                formatValues={formatValues}
              />
            )}
            <Box textAlign="center" mt={2}>
              <Button variant="contained" color="primary" type="submit">
                {intl.formatMessage(activation.confirm)}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </form>
  );
}
