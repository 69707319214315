import React from "react";
import { useIntl } from "react-intl";

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from "@material-ui/core";

import { PRODUCT_SUBTYPES } from "../../../utils/constants";
import { form } from "../../../messages";

export default function DynamicValue({ values, handleChange }) {
  const intl = useIntl();

  const onChange = (_, v) => {
    handleChange("dynamic_value")(v);
    if (v) handleChange("value")("");
  };

  return (
    [
      PRODUCT_SUBTYPES.COUPON_DISCOUNT,
      PRODUCT_SUBTYPES.COUPON_VALUE,
      PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE,
      PRODUCT_SUBTYPES.PROVISIONING_CARD,
    ].includes(values.subtype) && (
      <FormControl margin="normal">
        <FormControlLabel
          disabled={values.link_distribution}
          control={
            <Switch
              checked={values.dynamic_value}
              color="primary"
              key="dynamic_value"
              name="dynamic_value"
              onChange={onChange}
            />
          }
          label={intl.formatMessage(form.dynamicValue)}
        />
        {values.link_distribution && (
          <FormHelperText>
            {intl.formatMessage(form.dynamicValueHelp)}
          </FormHelperText>
        )}
      </FormControl>
    )
  );
}
