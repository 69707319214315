import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Container,
  FormControlLabel,
  IconButton,
  TextField,
} from "@material-ui/core";

import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import NumberInput from "../../ui/NumberInput";
import { common } from "../../messages";
import { Add, Delete } from "@material-ui/icons";

const EVENTS = {
  ISSUE: "Emissione",
  ISSUE_WL: "Emissione da lista d'attesa",
  EXP_REMIND: "Sollecito",
};

function Notification({ disabled, handleChange, name, title, values }) {
  const onAdd = () => {};
  const onDelete = () => {};

  const toggle = () => {
    values.exists ? onDelete() : onAdd();
    handleChange(name, "exists")(!values.exists);
  };

  return (
    <Box mb={2}>
      <Card>
        <CardHeader
          action={
            <IconButton disabled={disabled} onClick={toggle}>
              {values.exists ? <Delete /> : <Add />}
            </IconButton>
          }
          title={title}
        />
        {values.exists && (
          <CardContent>
            <Box style={{ width: "100%" }}>
              <Box mt={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.enabled}
                      onChange={handleChange(name, "enabled")}
                      color="primary"
                    />
                  }
                  label="Abilitata"
                />
              </Box>
              {name === "EXP_REMIND" && (
                <NumberInput
                  label="Giorni alla scadenza"
                  value={values.daysRemaining}
                  onChange={handleChange(name, "daysRemaining")}
                  fullWidth
                  max={9999}
                  required={values.enabled}
                />
              )}
              <Box mt={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.enabledApp}
                      onChange={handleChange(name, "enabledApp")}
                      color="primary"
                    />
                  }
                  label="Notifica in app"
                />
                {values.enabledApp && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.enabledPushApp}
                        onChange={handleChange(name, "enabledPushApp")}
                        color="primary"
                      />
                    }
                    label="Notifica push"
                    required
                  />
                )}

                {values.enabledApp && (
                  <TextField
                    margin="dense"
                    label={"Titolo"}
                    value={values.titleApp}
                    onChange={handleChange(name, "titleApp")}
                    fullWidth
                    required={values.enabled}
                  />
                )}
                {values.enabledApp && (
                  <TextField
                    margin="dense"
                    label={"Testo"}
                    value={values.textApp}
                    onChange={handleChange(name, "textApp")}
                    fullWidth
                    multiline
                    required={values.enabled}
                  />
                )}
              </Box>
              <Box mt={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.enabledEmail}
                      onChange={handleChange(name, "enabledEmail")}
                      color="primary"
                    />
                  }
                  label="Notifica email"
                />
                {values.enabledEmail && (
                  <TextField
                    margin="dense"
                    label={"Mittente"}
                    value={values.fromEmail}
                    onChange={handleChange(name, "fromEmail")}
                    fullWidth
                    required={values.enabled}
                  />
                )}
                {values.enabledEmail && (
                  <TextField
                    margin="dense"
                    label={"Oggetto"}
                    value={values.subjectEmail}
                    onChange={handleChange(name, "subjectEmail")}
                    fullWidth
                    required={values.enabled}
                  />
                )}
                {values.enabledEmail && (
                  <TextField
                    margin="dense"
                    label={"Testo"}
                    value={values.textEmail}
                    onChange={handleChange(name, "textEmail")}
                    fullWidth
                    required={values.enabled}
                    multiline
                  />
                )}
              </Box>
            </Box>
          </CardContent>
        )}
      </Card>
    </Box>
  );
}

export default function CampaignNotifications() {
  const { campaignId } = useParams();
  const intl = useIntl();
  const { bmapi, startLoading, stopLoading } = useBmapi();
  const [campaign, setCampaign] = useState(false);
  const [values, setValues] = useState({
    ISSUE: {
      type: "ISSUE",
      exists: false,
      enabled: false,
      enabledApp: false,
      enabledPushApp: false,
      enabledEmail: false,
      titleApp: "",
      textApp: "",
      subjectEmail: "",
      fromEmail: "",
      textEmail: "",
    },
    ISSUE_WL: {
      type: "ISSUE_WL",
      exists: false,
      enabled: false,
      enabledApp: false,
      enabledPushApp: false,
      enabledEmail: false,
      titleApp: "",
      textApp: "",
      subjectEmail: "",
      fromEmail: "",
      textEmail: "",
    },
    EXP_REMIND: {
      type: "EXP_REMIND",
      exists: false,
      enabled: false,
      enabledApp: false,
      enabledPushApp: false,
      enabledEmail: false,
      titleApp: "",
      textApp: "",
      subjectEmail: "",
      fromEmail: "",
      textEmail: "",
      daysRemaining: "",
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(values);
  };

  const handleChange = (notification, key) => {
    const updateValue = (val) => {
      setValues((v) => ({
        ...v,
        [notification]: { ...v[notification], [key]: val },
      }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  };

  useEffect(() => {
    startLoading();

    bmapi
      .getCampaign(campaignId)
      .then((res) => setCampaign(res.campaign))
      .finally(stopLoading);
  }, [bmapi, campaignId, startLoading, stopLoading]);

  console.log(campaign, EVENTS);

  return (
    !!campaign && (
      <Container maxWidth="sm">
        <Title>
          {intl.formatMessage(
            {
              id: "pages.notification.title",
              defaultMessage: "Notifiche per {name}",
            },
            campaign
          )}
        </Title>

        <form onSubmit={onSubmit}>
          <Notification
            handleChange={handleChange}
            name="ISSUE"
            title="Emissione"
            values={values.ISSUE}
          />

          <Notification
            disabled={!campaign.waiting_list}
            handleChange={handleChange}
            name="ISSUE_WL"
            title="Emissione da lista d'attesa"
            values={values.ISSUE_WL}
          />

          <Notification
            handleChange={handleChange}
            name="EXP_REMIND"
            title="Sollecito"
            values={values.EXP_REMIND}
          />

          <Button variant="contained" fullWidth color="primary" type="submit">
            {intl.formatMessage(common.save)}
          </Button>
        </form>
      </Container>
    )
  );
}
