import React from "react";
import { useIntl } from "react-intl";

import { PRODUCT_SUBTYPES } from "../../../utils/constants";

import NumberInput from "../../NumberInput";
import { product } from "../../../messages";

export default function MaxProductsPerUser({
  values,
  handleChange,
  productType,
  campaign,
}) {
  const intl = useIntl();

  return (
    ![
      PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE,
      PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE,
      PRODUCT_SUBTYPES.PROVISIONING_CARD,
    ].includes(values.subtype) && (
      <NumberInput
        name="max_products_per_user"
        label={intl.formatMessage(product[productType].maxProductsPerUser)}
        value={values.max_products_per_user}
        onChange={handleChange("max_products_per_user")}
        key="max_products_per_user"
        fullWidth
        margin="normal"
        disabled={!!values.link_distribution || !!campaign}
      />
    )
  );
}
