import React from "react";
import { useIntl } from "react-intl";

import { TextField } from "@material-ui/core";

import { PRODUCT_SUBTYPES } from "../../../utils/constants";
import { form } from "../../../messages";

export default function Currency({ values, handleChange, campaign }) {
  const intl = useIntl();

  return (
    [
      PRODUCT_SUBTYPES.COUPON_SIMPLE,
      PRODUCT_SUBTYPES.COUPON_VALUE,
      PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE,
      PRODUCT_SUBTYPES.PROVISIONING_CARD,
      PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE,
    ].includes(values.subtype) && (
      <TextField
        name="currency"
        label={intl.formatMessage(form.currency)}
        value={values.currency}
        onChange={handleChange("currency")}
        fullWidth
        required={!!values.value}
        margin="normal"
        disabled={!!campaign}
      />
    )
  );
}
