import React from "react";
import { useIntl } from "react-intl";

import NumberInput from "../../NumberInput";

import { PRODUCT_SUBTYPES } from "../../../utils/constants";
import { product } from "../../../messages";

export default function MaxIssueNumber({
  values,
  handleChange,
  productType,
  campaign,
}) {
  const intl = useIntl();

  return (
    ![
      PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE,
      PRODUCT_SUBTYPES.PROVISIONING_CARD,
    ].includes(values.subtype) && (
      <NumberInput
        name="max_issue_number"
        label={intl.formatMessage(product[productType].maxIssueNumber)}
        value={values.max_issue_number}
        onChange={handleChange("max_issue_number")}
        key="max_issue_number"
        fullWidth
        margin="normal"
        disabled={!!values.external_ids_format || !!campaign}
      />
    )
  );
}
