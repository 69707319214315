import {
  addDays,
  addMonths,
  differenceInDays,
  endOfMonth,
  format,
  isSameMonth,
} from "date-fns";

import { subtypeMap, typeMap } from "./campaigns";
import { BUSINESS_TYPES, PRODUCTS, PRODUCT_SUBTYPES } from "./constants";

const formatDate = (date) => (date ? format(date, "yyyy-MM-dd") : "");
const formatTime = (date) => (date ? format(date, "HH:mm") : "");
const dateToInput = (date) =>
  date && date !== "0001-01-01T00:00:00Z" ? new Date(date) : null;

function campaignFrontendType(campaign) {
  if (campaign.front_end_type) return campaign.front_end_type;

  switch (campaign.rules.subtype) {
    case PRODUCT_SUBTYPES.COUPON_SIMPLE:
      return campaign.rules.shot_number === 1
        ? PRODUCTS.COUPON_SIMPLE
        : PRODUCTS.COUPON_MULTISHOT;
    case PRODUCT_SUBTYPES.COUPON_DISCOUNT:
      return PRODUCTS.COUPON_DISCOUNT_PERC;
    case PRODUCT_SUBTYPES.COUPON_VALUE:
      return PRODUCTS.COUPON_DISCOUNT_VALUE;
    case PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE:
      return PRODUCTS.EARNING_CARD;
    case PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN:
      return PRODUCTS.INSTANT_WIN;
    case PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE:
      return PRODUCTS.SHOPPING_CARD;
    case PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE:
      return PRODUCTS.EVENT_PASS;
    case PRODUCT_SUBTYPES.PROVISIONING_CARD:
      return PRODUCTS.PROVISIONING_CARD;
  }
}

export function formatValues(values) {
  return {
    ...values,
    end_hour: formatTime(values.end_hour),
    earning_minimum_threshold:
      Math.round(values.earning_minimum_threshold * 100) || 0,
    expiration_date: formatDate(values.expiration_date),
    grace_period: parseInt(values.grace_period) || 0,
    max_issue_number:
      parseInt(values.max_issue_number) || (values.waiting_list ? -1 : 0),
    max_products_per_user: parseInt(values.max_products_per_user) || 0,
    product_lifespan: parseInt(values.product_lifespan) || 0,
    product_limit_date: formatDate(values.product_limit_date),
    quantum: Math.round(values.quantum * 100) || 100,
    rate: Math.round(values.rate * 100) || 100,
    reimbursement_rate: Math.round(values.reimbursement_rate * 100) || 0,
    shot_number: parseInt(values.shot_number) || 0,
    start_date: formatDate(values.start_date),
    start_hour: formatTime(values.start_hour),
    use_frequency: parseInt(values.use_frequency) || 0,
    use_period: parseInt(values.use_period) || 0,
    value: Math.round(values.value * 100) || 0,
  };
}

const formDefault = {
  _common: {
    auto_join: false,
    avatar_url: "",
    business_id: "",
    codes_prefix: "",
    cover: false,
    currency: "Euro",
    demo: false,
    description: "",
    dynamic_value: false,
    earning_minimum_threshold: "",
    end_hour: null,
    expiration_date: null,
    external_ids_format: "",
    front_end_type: "",
    grace_period: "",
    icon: false,
    image_url: "",
    instant_win_threshold: 0,
    link_distribution: false,
    loop: false,
    mailing_list: "",
    main_event_id: "",
    manager_restriction: false,
    max_issue_number: "",
    max_products_per_user: "",
    name: "",
    primary_product_not_mandatory: false,
    prizes: [],
    product_lifespan: "",
    product_limit_date: null,
    products_name: "",
    quantum: "",
    rate: "",
    reimbursement_rate: "",
    shot_number: "1",
    start_date: addDays(new Date(), 1),
    start_hour: null,
    subtype: "",
    tos_consumer_url: "",
    tos_merchant_url: "",
    type: "",
    use_frequency: "",
    use_period: "",
    value: "",
    waiting_list: false,
  },
  [PRODUCTS.COUPON_DISCOUNT_PERC]: {
    currency: "%",
    expiration_date: endOfMonth(addMonths(new Date(), 6)),
    product_lifespan: "3",
  },
  [PRODUCTS.COUPON_DISCOUNT_VALUE]: {
    expiration_date: endOfMonth(addMonths(new Date(), 6)),
    product_lifespan: "3",
  },
  [PRODUCTS.COUPON_MULTISHOT]: {
    expiration_date: endOfMonth(addMonths(new Date(), 6)),
    product_lifespan: "3",
  },
  [PRODUCTS.COUPON_SIMPLE]: {
    expiration_date: endOfMonth(addMonths(new Date(), 6)),
    product_lifespan: "3",
  },
  [PRODUCTS.COURSE_PASS]: {
    max_products_per_user: "1",
    primary_product_not_mandatory: true,
    shot_number: "-1",
  },
  [PRODUCTS.EARNING_CARD]: {
    currency: "",
    expiration_date: endOfMonth(addMonths(new Date(), 12)),
    product_limit_date: endOfMonth(addMonths(new Date(), 12)),
  },
  [PRODUCTS.EVENT_PASS]: {
    max_products_per_user: "1",
  },
  [PRODUCTS.INSTANT_WIN]: {
    currency: "",
    expiration_date: endOfMonth(addMonths(new Date(), 12)),
    link_distribution: true,
    product_limit_date: endOfMonth(addMonths(new Date(), 12)),
    value: "0",
  },
  [PRODUCTS.SHOPPING_CARD]: {
    expiration_date: endOfMonth(addMonths(new Date(), 12)),
    product_lifespan: "12",
  },
  [PRODUCTS.PROVISIONING_CARD]: {
    currency: "HH",
    expiration_date: endOfMonth(addMonths(new Date(), 6)),
    manager_restriction: true,
    max_products_per_user: "1",
    product_lifespan: "12",
  },
};

const businessInfo = (bmapi) => ({
  business_id: bmapi.getUserInfo().business.id,
  demo: !!bmapi.settings.demo,
  loop: bmapi.getUserInfo().business.type === BUSINESS_TYPES.LOOP,
});

const permanentValues = (values) => ({
  avatar_url: values.avatar_url,
  codes_prefix: values.codes_prefix,
  cover: values.cover,
  description: values.description,
  demo: values.demo,
  end_hour: values.end_hour,
  expiration_date: values.expiration_date,
  icon: values.icon,
  image_url: values.image_url,
  name: values.name,
  products_name: values.products_name,
  start_date: values.start_date,
  start_hour: values.start_hour,
  tos_consumer_url: values.tos_consumer_url,
  tos_merchant_url: values.tos_merchant_url,
});

const campaignToForm = (campaign) => ({
  ...campaign.rules,
  ...campaign,
  auto_join: campaign.rules.auto_join,
  avatar_url: campaign.avatar_url,
  codes_prefix: campaign.codes_prefix,
  currency: campaign.rules.currency,
  demo: campaign.demo,
  description: campaign.description,
  dynamic_value: campaign.rules.dynamic_value,
  earning_minimum_threshold: campaign.rules.earning_minimum_threshold
    ? campaign.rules.earning_minimum_threshold / 100
    : "",
  end_hour: campaign.end_hour || null,
  expiration_date: dateToInput(campaign.expiration_date),
  external_ids_format: campaign.external_ids_format,
  grace_period: campaign.grace_period || "",
  image_url: campaign.image_url,
  instant_win_threshold: campaign.rules.instant_win_threshold,
  link_distribution: campaign.link_distribution,
  loop: campaign.loop_campaign,
  mailing_list: campaign.mailing_list_id,
  main_event_id: campaign.rules.main_event_id,
  max_issue_number:
    campaign.max_issue_number > 0 ? campaign.max_issue_number : "",
  max_products_per_user: campaign.rules.max_products_per_user || "",
  name: campaign.name,
  prizes: campaign.rules.prizes || [],
  product_lifespan: campaign.product_lifespan || "",
  product_limit_date: dateToInput(campaign.product_limit_date),
  products_name: campaign.products_name,
  quantum: campaign.rules.quantum ? campaign.rules.quantum / 100 : "",
  rate: campaign.rules.rate ? campaign.rules.rate / 100 : "",
  reimbursement_rate: campaign.rules.reimbursement_rate
    ? campaign.rules.reimbursement_rate / 100
    : "",
  shot_number: campaign.rules.shot_number || "",
  start_date: dateToInput(campaign.start_date),
  start_hour: campaign.start_hour || null,
  tos_consumer_url: campaign.tos_consumer_url,
  tos_merchant_url: campaign.tos_merchant_url,
  use_frequency: campaign.rules.use_frequency || "",
  use_period: campaign.rules.use_period || "",
  value: campaign.rules.value ? campaign.rules.value / 100 : "",
  waiting_list: campaign.waiting_list,
});

const clonedValues = (campaign) => {
  const today = new Date();
  const campaignStartDate = new Date(campaign.start_date);
  const sameMonth = isSameMonth(campaignStartDate, today);
  const newStart = addDays(endOfMonth(today), 1);
  const dayDiff = differenceInDays(newStart, campaignStartDate);
  const newValues = {
    name: `(copy) ${campaign.name}`,
  };

  if (today < campaignStartDate) {
    return newValues;
  } else if (sameMonth) {
    return {
      ...newValues,
      start_date: dateToInput(today.toISOString()),
    };
  }
  return {
    ...newValues,
    expiration_date: dateToInput(
      campaign.expiration_date === "0001-01-01T00:00:00Z"
        ? campaign.expiration_date
        : endOfMonth(
            addDays(new Date(campaign.expiration_date), dayDiff)
          ).toISOString()
    ),
    product_limit_date: dateToInput(
      campaign.product_limit_date === "0001-01-01T00:00:00Z"
        ? campaign.product_limit_date
        : endOfMonth(
            addDays(new Date(campaign.product_limit_date), dayDiff)
          ).toISOString()
    ),
    start_date: dateToInput(newStart.toISOString()),
  };
};

export function getInitialState(fe_type, bmapi, values, campaign, clone) {
  const front_end_type = fe_type || campaignFrontendType(campaign);
  return {
    ...formDefault._common,
    ...(!bmapi ? {} : businessInfo(bmapi)),
    ...(!values ? {} : permanentValues(values)),
    ...formDefault[front_end_type],
    ...(!campaign ? {} : campaignToForm(campaign)),
    ...(!clone ? {} : clonedValues(campaign)),
    front_end_type,
    subtype: subtypeMap[front_end_type],
    type: typeMap[subtypeMap[front_end_type]],
  };
}
