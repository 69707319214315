import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add, Delete, Edit, MoreVert } from "@material-ui/icons";

import Confirm from "../ui/Confirm";
import FloatingActions, { Action } from "../ui/FloatingActions";
import Title from "../ui/Title";
import { useBmapi } from "../utils/bmapi-context";
import { BUSINESS_TYPES, USER_ROLES } from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";
import {
  common,
  confirm,
  form,
  notifications,
  roles,
  storeTypes,
  subscriptions,
} from "../messages";
import styles from "../utils/styles";
import PlanLimitAlert from "../ui/PlanLimitAlert";
import LimitProgress from "../ui/LimitProgress";

export const rolesLabels = {
  ASSISTANT: roles.storeAssistant,
  BUSINESS_MANAGER: roles.storeManager,
  CASHIER: roles.operator,
  EDITOR: roles.campaignManager,
  REPORTER: roles.viewer,
  SUBSCRIPTION_MANAGER: roles.subscriptionManager,
  SUBSCRIPTION_PLAN_MANAGER: roles.subscriptionPlanManager,
  SUBSCRIPTION_STAMP_MANAGER: roles.subscriptionStampManager,
};

export const getPermName = (code, intl) =>
  intl.formatMessage(
    rolesLabels[Object.entries(USER_ROLES).find((r) => r[1] === code)[0]]
  );

function BusinessElement({
  business,
  managers,
  onModify,
  onDelete,
  status,
  update,
}) {
  const classes = styles.useStyles();
  const intl = useIntl();
  const { bmapi, notifySuccess, notifyError } = useBmapi();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(null);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(USER_ROLES.BUSINESS_MANAGER);
  const [saving, setSaving] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);

  const permToName = (code) => getPermName(code, intl);

  const handleDelete = () => {
    setLoading(true);
    setAnchorEl(null);
    onDelete(business.id);
  };

  const handleModify = () => {
    setAnchorEl(null);
    onModify(business);
  };

  const handleAddManager = () => {
    setAnchorEl(null);
    setOpenDialog(true);
  };

  const handleRevokeManager = () => {
    setSaving(true);
    return bmapi
      .revokeUserPermission(deleteRequest.id, deleteRequest.email)
      .then(() => {
        notifySuccess(intl.formatMessage(notifications.managerRemoved));
        setDeleteRequest(false);
        update();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const handleCreateManager = (e) => {
    e.preventDefault();
    setSaving(true);

    bmapi
      .createManager(email.trim(), business.id, role)
      .then(() => {
        notifySuccess(intl.formatMessage(notifications.managerAdded));
        setOpenDialog(false);
        setEmail("");
        update();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const alphaSortManager = (a, b) => {
    return permToName(a.permission) !== permToName(b.permission)
      ? permToName(a.permission).localeCompare(permToName(b.permission))
      : a.email.localeCompare(b.email);
  };

  const availableManagers = bmapi.subscription
    ? bmapi.subscription.plan_limits.max_managers_per_business - managers.length
    : 10000;

  return (
    <React.Fragment>
      <Confirm
        open={!!deleteRequest}
        onConfirm={handleRevokeManager}
        onCancel={() => setDeleteRequest(false)}
        text={intl.formatMessage(confirm.deleteManager, deleteRequest)}
        flag
      />

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <form onSubmit={handleCreateManager}>
          <DialogTitle>
            <FormattedMessage
              id="component.manageStores.addManager"
              defaultMessage="Aggiungi manager"
            />
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={intl.formatMessage(common.store)}
              value={business.name}
              disabled
              fullWidth
            />
            <TextField
              autoFocus
              margin="dense"
              label={intl.formatMessage(common.email)}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage(common.role)}
              value={role}
              onChange={(e) => setRole(e.target.value)}
              required
              fullWidth
              select
              disabled={
                bmapi.subscription &&
                !bmapi.subscription.plan_limits.different_roles
              }
              helperText={
                bmapi.subscription &&
                !bmapi.subscription.plan_limits.different_roles
                  ? intl.formatMessage(subscriptions.notAvailableinTrial)
                  : false
              }
            >
              {Object.entries(rolesLabels)
                .filter(([role]) => !role.startsWith("SUBSCRIPTION_"))
                .map(([role, label]) => [role, intl.formatMessage(label)])
                .sort((a, b) => a[1].localeCompare(b[1]))
                .map(([role, label]) => (
                  <MenuItem value={USER_ROLES[role]} key={USER_ROLES[role]}>
                    {label}
                  </MenuItem>
                ))}
            </TextField>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} disabled={saving}>
              {intl.formatMessage(common.cancel)}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={saving}
            >
              {intl.formatMessage(common.create)}
            </Button>
          </DialogActions>
        </form>
        {saving && <LinearProgress />}
      </Dialog>

      <ListItem>
        <ListItemText
          primary={
            <React.Fragment>
              {business.name}{" "}
              {business.demo && (
                <Chip
                  component="span"
                  label="demo"
                  size="small"
                  className={classes.demoChip}
                />
              )}
            </React.Fragment>
          }
          primaryTypographyProps={{ variant: "h6" }}
          secondary={
            <>
              {business.category && (
                <strong>
                  [
                  {storeTypes[business.category]
                    ? intl.formatMessage(storeTypes[business.category])
                    : business.category}
                  ]{" "}
                </strong>
              )}
              {business.description || (
                <i>
                  <FormattedMessage
                    id="component.manageStores.noDescription"
                    defaultMessage="Nessuna descrizione"
                  />
                </i>
              )}
            </>
          }
        />
        <ListItemSecondaryAction>
          <span style={{ display: "inline-block", position: "relative" }}>
            <IconButton
              onClick={(e) => setAnchorEl(e.currentTarget)}
              edge="end"
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              keepMounted
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem disabled>
                <Typography variant="overline">{business.name}</Typography>
              </MenuItem>
              <MenuItem
                onClick={handleAddManager}
                disabled={availableManagers < 1}
              >
                <ListItemIcon>
                  <Add fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage
                    id="component.manageStores.addManager"
                    defaultMessage="Aggiungi manager"
                  />
                  {availableManagers < 1 && (
                    <small>
                      {" "}
                      [{intl.formatMessage(subscriptions.noSlots)}]
                    </small>
                  )}
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleModify}>
                <ListItemIcon>
                  <Edit fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage
                    id="component.manageStores.editStore"
                    defaultMessage="Modifica negozio"
                  />
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleDelete}>
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage
                    id="component.manageStores.deleteStore"
                    defaultMessage="Elimina negozio"
                  />
                </Typography>
              </MenuItem>
            </Menu>
            {loading && (
              <CircularProgress size={48} className={classes.fabProgress} />
            )}
          </span>
        </ListItemSecondaryAction>
      </ListItem>
      {!!managers.length && (
        <ListItem>
          <ListItemText
            primary={
              <React.Fragment>
                {managers.sort(alphaSortManager).map((m) => (
                  <Typography variant="body2" key={m.email}>
                    <strong>{permToName(m.permission)}</strong>:{" "}
                    {status[m.email]?.is_registered ? (
                      `${m.email} ✅ `
                    ) : (
                      <i>[{m.email}] </i>
                    )}
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => setDeleteRequest(m)}
                    >
                      {intl.formatMessage(common.delete)}
                    </Button>
                  </Typography>
                ))}
              </React.Fragment>
            }
          />
        </ListItem>
      )}
    </React.Fragment>
  );
}

const initialValues = (bs = {}) => ({
  address: bs.complete_address || "",
  business_group_id: bs.business_group_id || "DEFAULT",
  category: bs.category || "",
  city: bs.city || "",
  demo: bs.demo || false,
  description: bs.description || "",
  independent: false,
  mobile: bs.mobile || "",
  name: bs.name || "",
  province: bs.province || "",
  region: bs.region || "",
  vat_number: bs.vat_number || "",
  zip: bs.zip || "",
});

export default function ManageStores() {
  const intl = useIntl();
  const { bmapi, notifySuccess, notifyError } = useBmapi();
  const [openDialog, setOpenDialog] = useState(false);
  const [businesses, setBusinesses] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentId, setCurrentId] = useState(false);
  const [values, setValues] = useState(initialValues());
  const [status, setStatus] = useState({});

  const handleValue = useCallback((label) => {
    const updateValue = (val) => {
      setValues((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  const updateBusinesses = (bs) => {
    setBusinesses(
      (bs || []).filter(
        (b) => b.type === BUSINESS_TYPES.MERCHANT && b.status === 0
      ) || []
    );
  };

  const handleDelete = (id) => {
    bmapi
      .deleteBusiness(id)
      .then(() => bmapi.getBusinesses().then(updateBusinesses))
      .then(() =>
        notifySuccess(
          intl.formatMessage({
            id: "component.manageStores.storeRemoved",
            defaultMessage: "Negozio eliminato con successo",
          })
        )
      )
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  };

  const handleModify = (business) => {
    setValues(initialValues(business));
    setCurrentId(business.id);
    setOpenDialog(true);
  };

  const clear = () => {
    setValues(initialValues());
    setOpenDialog(false);
    setCurrentId(false);
  };

  const handleCreate = (e) => {
    e.preventDefault();
    setSaving(true);

    bmapi
      .saveBusiness(values, currentId)
      .then(() =>
        Promise.all([bmapi.getBusinesses(), bmapi.getManagers()]).then(
          ([bs, ps]) => {
            updateBusinesses(bs);
            setPermissions(ps);
          }
        )
      )
      .then(() => {
        notifySuccess(
          intl.formatMessage({
            id: "component.manageStores.storeSaved",
            defaultMessage: "Negozio salvato con successo",
          })
        );
        clear();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const byName = (a, b) => a.name.localeCompare(b.name);

  const update = useCallback(() => {
    setLoading(true);
    Promise.all([bmapi.getBusinesses(), bmapi.getManagers()])
      .then(([bs, ps]) => {
        updateBusinesses(bs);
        setPermissions(ps);
        setLoading(false);
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  }, [bmapi, intl, notifyError]);

  useEffect(() => {
    update();
  }, [update]);

  useEffect(() => {
    if (permissions) {
      permissions.forEach((perm) =>
        setStatus((st) =>
          st[perm.email]
            ? st
            : {
                ...st,
                [perm.email]: bmapi
                  .checkManagerRegistration(perm.id)
                  .then((res) =>
                    setStatus((s) => ({ ...s, [perm.email]: res }))
                  )
                  .catch(console.error),
              }
        )
      );
    }
  }, [bmapi, intl, notifyError, permissions]);

  const createLink = (str) => (
    <Link onClick={() => setOpenDialog(true)}>{str}</Link>
  );

  const limitReached =
    bmapi.subscription &&
    bmapi.subscription.plan_limits.max_business > 0 &&
    bmapi.subscription.plan_limits.max_business <= businesses.length;

  return (
    <Container maxWidth="sm">
      <Title>
        {intl.formatMessage({
          id: "pages.stores.title",
          defaultMessage: "Negozi",
        })}
      </Title>
      {bmapi.subscription &&
        bmapi.subscription.plan_limits.max_business > 0 && (
          <LimitProgress
            value={businesses?.length || 0}
            limit={bmapi.subscription.plan_limits.max_business}
          />
        )}

      <Card>
        {(!businesses || loading) && <LinearProgress />}
        <Dialog
          open={openDialog && (!limitReached || currentId)}
          onClose={clear}
        >
          <form onSubmit={handleCreate}>
            <DialogTitle>
              {currentId ? (
                <FormattedMessage
                  id="component.manageStores.editStore"
                  defaultMessage="Modifica negozio"
                />
              ) : (
                <FormattedMessage
                  id="component.manageStores.createStore"
                  defaultMessage="Crea negozio"
                />
              )}
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label={intl.formatMessage(form.name)}
                value={values.name}
                onChange={handleValue("name")}
                required
                fullWidth
              />
              <TextField
                margin="dense"
                label={intl.formatMessage(form.description)}
                value={values.description}
                onChange={handleValue("description")}
                fullWidth
              />
              <TextField
                margin="dense"
                label={intl.formatMessage(form.category)}
                value={values.category}
                onChange={handleValue("category")}
                fullWidth
                select={!!bmapi.settings.businessCategories}
              >
                {!!bmapi.settings.businessCategories && (
                  <MenuItem value="">
                    {intl.formatMessage(form.noCategory)}
                  </MenuItem>
                )}
                {(bmapi.settings.businessCategories || []).map((category) => (
                  <MenuItem value={category} key={category}>
                    {storeTypes[category]
                      ? intl.formatMessage(storeTypes[category])
                      : category}
                  </MenuItem>
                ))}
              </TextField>
              <FormControl margin="normal">
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.demo}
                      color="primary"
                      onChange={handleValue("demo")}
                      key="demo"
                      name="demo"
                    />
                  }
                  label={intl.formatMessage(form.demoStore)}
                />
              </FormControl>
              {/* <TextField
              margin="dense"
              label={intl.formatMessage(form.address)}
              value={values.address}
              onChange={handleValue("address")}
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage(form.city)}
              value={values.city}
              onChange={handleValue("city")}
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage(form.zip)}
              value={values.zip}
              onChange={handleValue("zip")}
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage(form.province)}
              value={values.province}
              onChange={handleValue("province")}
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage(form.region)}
              value={values.region}
              onChange={handleValue("region")}
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage(form.mobile)}
              value={values.mobile}
              onChange={handleValue("mobile")}
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage(form.vatNumber)}
              value={values.vat_number}
              onChange={handleValue("vat_number")}
              fullWidth
            /> */}
            </DialogContent>
            <DialogActions>
              <Button onClick={clear} disabled={saving}>
                <FormattedMessage id="common.cancel" defaultMessage="Annulla" />
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={saving}
              >
                {currentId ? (
                  <FormattedMessage id="common.save" defaultMessage="Salva" />
                ) : (
                  <FormattedMessage id="common.create" defaultMessage="Crea" />
                )}
              </Button>
            </DialogActions>
          </form>
          {saving && <LinearProgress />}
        </Dialog>

        <PlanLimitAlert
          open={!!(openDialog && limitReached && !currentId)}
          onClose={clear}
        />

        {businesses &&
          (!businesses.length ? (
            <CardContent>
              <Typography gutterBottom>
                <FormattedMessage
                  id="component.manageStores.noStoreRegistered"
                  defaultMessage="Nessun negozio ancora registrato."
                />
              </Typography>
              <Typography>
                <FormattedMessage
                  id="component.manageStores.emptyAction"
                  defaultMessage="Crea il primo negozio cliccando sul bottone a fondo pagina o <link>qui</link>."
                  values={{ link: createLink }}
                />
              </Typography>
            </CardContent>
          ) : (
            <List component="div" disablePadding>
              {businesses.sort(byName).map((b, i) => (
                <React.Fragment key={b.id}>
                  {i !== 0 ? <Divider component="li" /> : null}
                  <BusinessElement
                    business={b}
                    managers={(permissions || []).filter(
                      (p) => p.resource_id === b.id
                    )}
                    onDelete={handleDelete}
                    onModify={handleModify}
                    status={status}
                    update={update}
                  />
                </React.Fragment>
              ))}
            </List>
          ))}

        <FloatingActions>
          <Action
            icon={<Add />}
            label={intl.formatMessage({
              id: "component.manageStores.createStore",
              defaultMessage: "Crea negozio",
            })}
            action={() => setOpenDialog(true)}
          />
        </FloatingActions>
      </Card>
    </Container>
  );
}
