import React from "react";
import { useIntl } from "react-intl";

import NumberInput from "../../NumberInput";
import { PRODUCT_TYPES } from "../../../utils/constants";
import { form } from "../../../messages";

export default function EarningMinimumThreshold({
  values,
  handleChange,
  campaign,
}) {
  const intl = useIntl();

  return (
    [PRODUCT_TYPES.CAMPAIGN_EARNING_CARD].includes(values.type) && (
      <NumberInput
        name="earning_minimum_threshold"
        label={intl.formatMessage(form.earningMinimumThreshold)}
        value={values.earning_minimum_threshold}
        onChange={handleChange("earning_minimum_threshold")}
        fullWidth
        margin="normal"
        disabled={!!campaign}
      />
    )
  );
}
