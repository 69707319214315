import React from "react";
import { useBmapi } from "../../utils/bmapi-context";
import { TENANTS } from "../../utils/constants";
import Products from "./Products";
import Pricing from "../../components/Pricing";
import Subscriptions from "./Subscriptions";
import Content from "../Content";

export default function Home() {
  const { bmapi, userId } = useBmapi();

  if (bmapi.tenant === TENANTS.SELF) {
    return userId ? <Subscriptions /> : <Pricing />;
  }

  return userId ? <Products /> : <Content content="home" />;
}
