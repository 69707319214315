import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Box, Button, Fade, Typography } from "@material-ui/core";
import { useBmapi } from "../utils/bmapi-context";

const bannerStyle = {
  background: "rgba(0, 0, 0, 0.75)",
  bottom: 0,
  color: "#fff",
  left: 0,
  padding: 10,
  position: "fixed",
  right: 0,
  zIndex: 2000,
};

export default function CookieBanner() {
  const intl = useIntl();
  const { bmapi } = useBmapi();
  const [hidden, setHidden] = useState(bmapi.getTenantData().cookieShown);

  const hide = useCallback(() => {
    bmapi.setTenantData({ cookieShown: true });
    setHidden(true);
  }, [bmapi]);

  useEffect(() => {
    if (!hidden) {
      document.addEventListener("scroll", hide);
      return () => document.removeEventListener("scroll", hide);
    }
  }, [hidden, hide]);

  return (
    <Fade in={!hidden}>
      <div style={bannerStyle}>
        <Typography variant="caption">
          {intl.formatMessage({
            id: "cookie.info",
            defaultMessage:
              "Questo sito utilizza i cookies per consentirti la migliore navigazione. Il sito è impostato per consentire l'utilizzo di tutti i cookies. Il sito utilizza link e componenti di terze parti che potrebbero a loro volta generare cookie dei quali non possiamo essere responsabili. Se vuoi saperne di più o negare il consenso a tutti o ad alcuni cookie, consulta l’informativa sulla \"Cookie Policy\" completa. Chiudendo questo banner, scorrendo questa pagina, cliccando su un link o proseguendo la navigazione in altra maniera, acconsenti l'uso dei cookie.",
          })}
        </Typography>
        <Box mt={1}>
          <Button
            size="small"
            variant="contained"
            onClick={hide}
            style={{ marginRight: 10 }}
          >
            {intl.formatMessage({
              id: "cookie.accept",
              defaultMessage: "Accetto",
            })}
          </Button>
          <Button
            size="small"
            variant="contained"
            href="https://liberacta.com/cookie-policy-it/"
            target="_blank"
          >
            {intl.formatMessage({
              id: "cookie.readMore",
              defaultMessage: "Leggi di più",
            })}
          </Button>
        </Box>
      </div>
    </Fade>
  );
}
