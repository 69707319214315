import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  Cancel,
  CheckCircle,
  HourglassEmpty,
  Publish,
} from "@material-ui/icons";

import { common, subscriptions } from "../messages";
import Grid from "../ui/Grid";
import Ribbon from "../ui/Ribbon";
import Title from "../ui/Title";
import { useBmapi } from "../utils/bmapi-context";
import { CONSUMER_ROUTES, PLANS } from "../utils/constants";
import { mergeSubscrPlans, objToQuery } from "../utils/utils";

const CURRENCY_SCALE = 100;

export const tiers = [
  {
    //name: "BMarkEn SM base SP",
    //shortname: "Base",
    id: "SMBAS",
    //pricing: { 1: 20, 3: 60, 6: 110, 12: 200 },
    //quantityDiscount: [1],
    specs: [
      { label: subscriptions.couponsPerMonth, type: "limit", num: 300 },
      { label: subscriptions.accounts, type: "limit", num: 3 },
      {
        label: subscriptions.unlimitedCampaings,
        type: "ok",
        description: subscriptions.unlimitedCampaingsDesc,
      },
      {
        label: subscriptions.roles,
        type: "ko",
        description: subscriptions.rolesOff,
      },
      {
        label: subscriptions.loopCampaigns,
        type: "ko",
        description: subscriptions.loopCampaignsOff,
      },
    ],
  },
  {
    //name: "BMarkEn SM standard SP",
    //shortname: "Standard",
    id: "SMSTD",
    //pricing: { 1: 40, 3: 120, 6: 220, 12: 400 },
    //quantityDiscount: [1, 0.75, 0.75, 0.75, 0.5],
    best: true,
    specs: [
      { label: subscriptions.couponsPerMonth, type: "limit", num: 1000 },
      { label: subscriptions.accounts, type: "limit", num: 5 },
      {
        label: subscriptions.unlimitedCampaings,
        type: "ok",
        description: subscriptions.unlimitedCampaingsDesc,
      },
      {
        label: subscriptions.roles,
        type: "ok",
        description: subscriptions.rolesOn,
      },
      {
        label: subscriptions.loopCampaigns,
        type: "ko",
        description: subscriptions.loopCampaignsOff,
      },
    ],
  },
  {
    //name: "BMarkEn SM advanced SP",
    //shortname: "Advanced",
    id: "SMADV",
    //pricing: { 1: 80, 3: 240, 6: 440, 12: 800 },
    //quantityDiscount: [1, 0.75, 0.75, 0.75, 0.5],
    specs: [
      { label: subscriptions.couponsPerMonth, type: "limit", num: 2500 },
      { label: subscriptions.accounts, type: "limit", num: 10 },
      {
        label: subscriptions.unlimitedCampaings,
        type: "ok",
        description: subscriptions.unlimitedCampaingsDesc,
      },
      {
        label: subscriptions.roles,
        type: "ok",
        description: subscriptions.rolesOn,
      },
      {
        label: subscriptions.loopCampaigns,
        type: "ok",
        description: subscriptions.loopCampaignsOn,
      },
    ],
  },
  {
    //name: "BMarkEn SM trial SP",
    action: "enable",
    name: "Trial",
    //shortname: "Trial",
    id: PLANS.TRIAL,
    pricing: [
      { quantity: 1, value: 0 },
      { quantity: 3, value: 0 },
      { quantity: 6, value: 0 },
      { quantity: 12, value: 0 },
    ],
    quantityDiscount: [1],
    specs: [
      { label: subscriptions.planWithLimits, type: "ok", plan: "Base" },
      { label: subscriptions.maxMonths, type: "time", num: 3 },
      { label: subscriptions.campaigns, type: "limit", num: 25 },
      { label: subscriptions.couponsPerCampaign, type: "limit", num: 300 },
      { label: subscriptions.cardPerCampaign, type: "limit", num: 100 },
    ],
  },
  {
    //name: "BMarkEn RETAIL premium SP",
    action: "contact",
    name: "Retail",
    //shortname: "Retail",
    id: "RETAIL",
    pricing: {},
    specs: [
      { label: subscriptions.planName, type: "ok", plan: "Advanced" },
      { label: subscriptions.crm, type: "ok" },
      { label: subscriptions.instantWin, type: "ok" },
      { label: subscriptions.trackVisits, type: "ok" },
      { label: subscriptions.unlimitedStores, type: "ok" },
      { label: subscriptions.couponsPerMonth, type: "limit", num: 20000 },
    ],
  },
];

export default function Pricing() {
  const theme = useTheme();
  const intl = useIntl();
  const history = useHistory();
  const { bmapi, userId } = useBmapi();
  const [billingPeriod, setBillingPeriod] = useState(1);
  const [plans, setPlans] = useState([]);

  const buy = (id, period) => {
    const formPage = `${CONSUMER_ROUTES.JOIN}${objToQuery({
      plan: id,
      billingPeriod: id === PLANS.TRIAL ? 3 : period || billingPeriod,
    })}`;
    if (userId) {
      history.push(formPage);
    } else {
      bmapi.setCallbackUrl(formPage);
      history.push(CONSUMER_ROUTES.SIGN_IN);
    }
  };

  const Ok = () => (
    <CheckCircle style={{ color: theme.palette.success.main }} />
  );
  const Ko = () => <Cancel style={{ color: theme.palette.error.main }} />;
  const Limit = () => <Publish />;
  const Time = () => <HourglassEmpty />;
  const icons = { ko: Ko, limit: Limit, ok: Ok, time: Time };

  useEffect(() => {
    bmapi
      .getSubscriptionPlans()
      .then((resp) => {
        setPlans(mergeSubscrPlans(tiers, resp));
      })
      .catch((e) => console.log("...subscriptions error", e));
  }, [bmapi]);

  const priceText = (ptier) => {
    const value = ptier.pricing.find((p) => p.quantity === billingPeriod).value;
    return Number.parseFloat(value / billingPeriod / CURRENCY_SCALE).toFixed(2);
  };

  return (
    !!plans.length && (
      <Container maxWidth="md">
        <Title>{intl.formatMessage(subscriptions.title)}</Title>

        <Box mb={6}>
          <Tabs
            value={billingPeriod}
            indicatorColor="primary"
            textColor="primary"
            onChange={(_, v) => setBillingPeriod(v)}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              value={1}
              label={intl.formatMessage(subscriptions.paymentMonthly)}
            />
            <Tab
              value={6}
              label={`${intl.formatMessage(
                subscriptions.paymentSemiannual
              )} (-8%)`}
            />
            <Tab
              value={12}
              label={`${intl.formatMessage(
                subscriptions.paymentAnnual
              )} (-16%)`}
            />
          </Tabs>
        </Box>

        <Box mb={6}>
          <Grid
            items={plans}
            render={(tier) => (
              <Card style={{ position: "relative" }}>
                {!!tier.best && (
                  <Ribbon label={intl.formatMessage(subscriptions.best)} />
                )}
                <CardHeader
                  //title={tier.shortname}
                  title={tier.name}
                  titleTypographyProps={{ variant: "h5", gutterBottom: true }}
                  style={{ padding: "16px" }}
                  //subheader={tier.name}
                />
                <CardContent>
                  {tier.action !== "contact" ? (
                    <Box display="flex" alignItems="baseline" flexWrap="wrap">
                      <Typography variant="h2" style={{ marginRight: 5 }}>
                        €{priceText(tier).split(".")[0]}
                        <small style={{ fontSize: "0.5em" }}>
                          ,{priceText(tier).split(".")[1]}
                        </small>
                      </Typography>
                      <Typography variant="overline">
                        /{intl.formatMessage(common.month)}
                        {tier.pricing[billingPeriod] !== 0 &&
                          `/${intl.formatMessage(common.store)}`}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography variant="h2" style={{ marginRight: 5 }}>
                      <small style={{ fontSize: "0.75em" }}>
                        <Link href="mailto:info@liberacta.com">
                          {intl.formatMessage(subscriptions.contactUs)}
                        </Link>
                      </small>
                    </Typography>
                  )}
                </CardContent>

                <List dense>
                  {(tier.specs || []).map((spec, i) => (
                    <ListItem key={i}>
                      <ListItemIcon>{icons[spec.type]()}</ListItemIcon>
                      <Tooltip
                        title={
                          spec.description
                            ? intl.formatMessage(spec.description)
                            : ""
                        }
                        arrow
                      >
                        <ListItemText
                          primary={intl.formatMessage(spec.label, spec)}
                        />
                      </Tooltip>
                    </ListItem>
                  ))}
                </List>
                <CardContent>
                  {tier.action === "contact" && (
                    <Button
                      variant="contained"
                      color={tier.best ? "primary" : undefined}
                      fullWidth
                      href="mailto:info@liberacta.com"
                    >
                      {intl.formatMessage(subscriptions.requestInfo)}
                    </Button>
                  )}
                  {tier.action === "enable" && (
                    <Button
                      variant="contained"
                      color={tier.best ? "primary" : undefined}
                      fullWidth
                      onClick={() => buy(tier.id)}
                    >
                      {intl.formatMessage(subscriptions.enable)}
                    </Button>
                  )}
                  {!tier.action && (
                    <Button
                      variant="contained"
                      color={tier.best ? "primary" : undefined}
                      fullWidth
                      onClick={() => buy(tier.id)}
                    >
                      {intl.formatMessage(subscriptions.buy)}
                    </Button>
                  )}
                </CardContent>
              </Card>
            )}
          />
        </Box>
      </Container>
    )
  );
}
