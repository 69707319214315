import React, { forwardRef } from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

import { MenuItem, ListItemIcon, ListItemText, Badge } from "@material-ui/core";

import { useBmapi } from "../utils/bmapi-context";
import styles from "../utils/styles";

export default function ListItemLink({ link, onClick }) {
  const { bmapi, startLoading, stopLoading } = useBmapi();
  const intl = useIntl();
  const classes = styles.useStyles();

  const LinkBase = (props, ref) =>
    link.url && link.url.includes("://") ? (
      <a href={link.url} target="_blank" rel="noopener noreferrer" {...props}>
        {props.children}
      </a>
    ) : (
      <RouterLink to={link.url || "#"} {...props} innerRef={ref} />
    );

  function handleClick(e) {
    if (link.action) {
      e.preventDefault();
      startLoading();
      link.action(e).then(stopLoading);
    }
    onClick(e);
  }

  return (
    <MenuItem
      button
      component={forwardRef(LinkBase)}
      onClick={handleClick}
      selected={link.active}
      classes={{ root: classes.menuItem, selected: classes.menuItemSelected }}
    >
      {!bmapi.settings.hideMenuIcon && (
        <ListItemIcon
          style={{ color: "inherit" }}
          className={classes.menuItemIcon}
        >
          <Badge color="secondary" variant="dot" invisible={!link.badge}>
            {link.icon}
          </Badge>
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          typeof link.label === "string"
            ? link.label
            : intl.formatMessage(link.label)
        }
        primaryTypographyProps={{
          style: {
            whiteSpace: "noWrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
        secondary={link.sublabel || false}
        secondaryTypographyProps={{
          color: "inherit",
          style: {
            whiteSpace: "noWrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
      />
    </MenuItem>
  );
}
