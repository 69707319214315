import React from "react";
import { useIntl } from "react-intl";

import { Typography } from "@material-ui/core";

import { DatePicker } from "../../DatePicker";
import { PRODUCT_SUBTYPES } from "../../../utils/constants";
import { form, product } from "../../../messages";

export default function ProductLimitDate({
  values,
  handleChange,
  productType,
  campaign,
}) {
  const intl = useIntl();

  return (
    values.subtype !== PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE && (
      <React.Fragment>
        <Typography variant="caption" display="block" gutterBottom>
          {intl.formatMessage(product[productType].productValidityInfo)}
        </Typography>

        <DatePicker
          label={intl.formatMessage(product[productType].productLimitDate)}
          value={values.product_limit_date}
          onChange={handleChange("product_limit_date")}
          key="product_limit_date"
          fullWidth
          clearable
          autoOk
          required={!values.product_lifespan}
          minDate={values.expiration_date}
          minDateMessage={intl.formatMessage(form.productLimitDateError)}
          disabled={!!campaign}
        />
      </React.Fragment>
    )
  );
}
