import React from "react";
import { useIntl } from "react-intl";

import { TimePicker } from "../../DatePicker";

import { PRODUCTS } from "../../../utils/constants";
import { form } from "../../../messages";

export default function EndHour({ values, handleChange }) {
  const intl = useIntl();

  return (
    values.front_end_type === PRODUCTS.EVENT_PASS && (
      <TimePicker
        label={intl.formatMessage(form.endHour)}
        value={values.end_hour}
        onChange={handleChange("end_hour")}
        key="end_hour"
        fullWidth
        autoOk
        clearable
        required={!!values.start_hour}
      />
    )
  );
}
